
<template>
  <v-card class="pa-0 text-right" elevation="0" tile>
    <v-card-title class="pa-2">
      {{$t('delivery.lang_orderStatusEdit')}}
    </v-card-title>
    <v-divider class="ma-0"/>
    <v-card-text class="pa-0 ma-0">
      <v-container>
        <v-form v-model="valid" ref="form">
          <v-row justify="center" justify-md="center" justify-lg="start" justify-xl="start">
            <v-col cols="12" sm="6" md="5" lg="5" xl="4">
              <v-text-field outlined
                            @focus="showTouchKeyboard"
                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            v-model="name"
                            :label="$t('generic.lang_name')"
                            :rules="[v=>(!!v) || $t('generic.lang_requiredField')]"
                            autocomplete="off"
                            required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="5" lg="5" xl="4">
              <v-text-field outlined
                            @focus="showTouchKeyboard"
                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            v-model="duration"
                            :label="$t('generic.lang_durationInMin')"
                            autocomplete="off"
                            type="number"
                            :rules="[v=>(!!v && parseInt(v)>=0) || $t('generic.lang_thisFieldIsRequiredAndMustBePositive')]"
                            required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="5" lg="5" xl="4">
              <fontawesome-picker :fieldLabel="$t('generic.lang_chooseIcon')"
                                  :value="icon"
                                  v-model="icon"
                                  type="status"
              ></fontawesome-picker>
            </v-col>
            <v-col cols="12" sm="6" md="5" lg="5" xl="4" >
              <v-textarea :label="$t('generic.lang_message')"
                          outlined
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          v-model="message"
                          autocomplete="off"
                          type="number"
              >

              </v-textarea>
            </v-col>
            <v-col cols="12" sm="6" md="5" lg="5" xl="4" >
              <div class="widget-content text-left pa-0">
                <strong>{{$t('generic.lang_notifyWith')}}:</strong>
              <v-checkbox class="my-0" hide-details :disabled="noNotify" v-model="notify" :label="$t('generic.lang_notification')" value="notification" ></v-checkbox>
              <v-checkbox class="my-0" hide-details :disabled="noNotify" v-model="notify" label="SMS" value="sms" ></v-checkbox>
              <v-checkbox class="my-0" hide-details :disabled="noNotify" v-model="notify" :label="$t('generic.lang_email')" value="email" ></v-checkbox>
              <v-checkbox class="my-0" hide-details v-model="noNotify" :label="$t('generic.lang_gen_none')"></v-checkbox>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="5" lg="5" xl="4">
              <div class="widget-content text-left pa-0">
                <span class="font-weight-medium">{{$t('generic.lang_status_color')}}:</span>
                <swatches
                    inline  v-model="color"
                    background-color="transparent"
                ></swatches>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="5" lg="5" xl="4" >
              <v-switch v-model="status" class="" :label="$t('settings.lang_settings_activ')" inset hide-details/>
            </v-col>

          </v-row>
        </v-form>
        <v-row justify="center" no-gutters>
          <v-col cols="10" xl="12" align="end">
            <v-btn large color="success" :disabled="!valid" :loading="loading" @click="update()">
              {{$t('generic.lang_apply')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </v-card>
</template>

<script>
import mixin from '@/mixins/KeyboardMixIns'
import FontawesomePicker from "@/components/common/iconPicker";
import Swatches from 'vue-swatches';
import "vue-swatches/dist/vue-swatches.css"
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "@/config";

export default {
  name: "OrderStatusEditComponent",
  components:{FontawesomePicker,Swatches},
  mixins:[mixin],
  data(){
    return {
      ENDPOINTS,
      id:null,
      valid:false,
      loading:false,
      icon:null,
      color:null,
      name:null,
      duration:0,
      noNotify:false,
      message:'',
      notify:[],
      status:false,
    }
  },
  methods:{
    update(){
      if(!this.$refs.form.validate()){
        return;
      }

      this.loading=true;

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.DELIVERYSTATUS.UPDATE,{
        id:this.id,
        icon:this.icon,
        color:this.color,
        name:this.name,
        duration:parseInt(Number(this.duration)),
        message:this.message,
        notifications:this.notify,
        active:this.status,
      }).then(res=>{
        if(res.data.status==='SUCCESS'){
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_updatedSuccessfully'),
            color: "success"
          });
          this.$router.go(-1);
        }else{
          Events.$emit("showSnackbar", {
            message:res.data.msg||res.data.status,
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin=>{
        this.loading=false;
      })

    },
    getData(){
      this.loading=true;

      this.id=parseInt(Number(this.$route.params.id));

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.DELIVERYSTATUS.GET,{
        id:this.id
      }).then(res=>{
            if(res.data.status==='SUCCESS'){
              this.icon=res.data.data.icon
              this.color=res.data.data.color
              this.name=res.data.data.name
              this.duration=res.data.data.duration;
              this.status=res.data.data.duration;
              this.message=res.data.data.message;
              this.notify=res.data.data.notifications;
            }else{
              Events.$emit("showSnackbar", {
                message: res.data.msg,
                color: "error"
              });
            }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin=>{
        this.loading=false;
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>

</style>

